/* global ApplePaySession */
import React, { useEffect, useState } from "react";
import "../style/payment.css";
import { jwtDecode } from "jwt-decode";
import CreatePayment from "../checkout/PaymentPage";
import { getPaymentDetail } from "../../Api/helper";
import { useTranslation } from "react-i18next";
import { IoCheckmark } from "react-icons/io5";
import { MdCreditCard } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CiLock } from "react-icons/ci";
import axios from "axios";
const Payment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState();
  const [discountPercentage, setDiscountPercentage] = useState();
  const [discountCalculated, setDiscountCalculated] = useState();
  const [selectedPackage, setSelectedPackage] = useState(2.99);
  const [showCardOption, setShowCardOption] = useState(false);
  const [total, setTotal] = useState();
  const [applePaySessionActive, setApplePaySessionActive] = useState(false);
  const subscriptionAmount = parseFloat(
    process.env.REACT_APP_SUBSCRIPTION_AMOUNT
  );
  const discountPercent = parseFloat(
    process.env.REACT_APP_SUBSCRIPTION_DISCOUNT_PERCENT
  );
  const discountAmount = (subscriptionAmount * discountPercent) / 100;
  const totalAmount = subscriptionAmount - discountAmount;
  const token = localStorage.getItem("token");
  // const decoded = jwtDecode(token);
  let decoded;
  if (token) {
    decoded = jwtDecode(token);
  }
  const is_trial_taken = decoded?.user?.is_trial_taken;

  const [showApplePay, setShowApplePay] = useState(false);
  useEffect(() => {
    const checkApplePaySupport = () => {
      if (typeof window !== "undefined" && window.ApplePaySession) {
        if (window.ApplePaySession.canMakePayments()) {
          setShowApplePay(true);
        }
      }
    };
    checkApplePaySupport();

    const getData = async () => {
      const result = await getPaymentDetail();
      setPrice((result?.data?.data?.price?.unit_amount / 100).toFixed(2));
    };
    getData();
  }, []);

  // const handleApplePay = async () => {
  //   if (!window.ApplePaySession) {
  //     console.error('Apple Pay is not available on this device/browser');
  //     return;
  //   }
  
  //   // Check if there's already an active Apple Pay session
  //   if (applePaySessionActive) {
  //     console.log('An Apple Pay session is already active');
  //     return;
  //   }
  
  //   // Mark the session as active
  //   setApplePaySessionActive(true);
  
  //   // Create ApplePay session only when necessary
  //   const applePaySession = new ApplePaySession(3, {
  //     countryCode: "US",
  //     currencyCode: "USD",
  //     supportedNetworks: ["visa", "masterCard", "amex"],
  //     merchantCapabilities: ["supports3DS"],
  //     total: {
  //       label: "Plenumapp",
  //       amount: price,
  //     },
  //   });
  //   console.log('ApplePay session created:', applePaySession);
  
  //   // Add event listeners to handle the flow
  //   applePaySession.onvalidatemerchant = async (event) => {
  //     console.log('Apple Pay session validation started');
  //     try {
  //       const merchantValidationData = await axios.post(`${process.env.REACT_APP_BASE_URL}api/apple-pay/validate-merchant`, {
  //         validationUrl: event.validationURL,
  //       });
  
  //       console.log("Merchant validation result:", merchantValidationData);
  
  //       if (merchantValidationData?.data) {
  //         applePaySession.completeMerchantValidation(merchantValidationData.data);
  //       } else {
  //         throw new Error('No validation data received');
  //       }
  //     } catch (error) {
  //       console.error('Merchant validation failed:', error);
  //       applePaySession.completeMerchantValidation({ error: 'Merchant validation failed' });
  //       setApplePaySessionActive(false); // Reset session state after failure
  //     }
  //   };
  
  //   applePaySession.onpaymentauthorized = async (event) => {
  //     const payment = event.payment;
  
  //     // Log the entire payment object to understand its structure
  //     console.log('Apple Pay payment object:', payment);
  
  //     if (!payment || !payment.token || !payment.token.paymentMethodId) {
  //       console.error('Missing payment method id in payment data');
  //       applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
  //       setApplePaySessionActive(false);  // Reset session state after failure
  //       return;
  //     }
  
  //     try {
  //       // Create Payment Intent with backend
  //       const paymentIntentRes = await axios.post(`${process.env.REACT_APP_BASE_URL}api/apple-pay/create-payment-intent`, {
  //         amount: price, // Amount in cents
  //       });
  
  //       console.log("Payment Intent response:", paymentIntentRes);
  
  //       const { clientSecret } = paymentIntentRes.data;
  
  //       // Confirm payment with Stripe
  //       const stripePaymentResult = await axios.post(`${process.env.REACT_APP_BASE_URL}api/apple-pay/confirm-payment`, {
  //         clientSecret,
  //         paymentMethodId: payment.token.paymentMethodId,
  //       });
  
  //       console.log("Stripe payment confirmation result:", stripePaymentResult);
  
  //       if (stripePaymentResult.data.error) {
  //         console.error('Payment confirmation failed');
  //         applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
  //       } else {
  //         applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
  //       }
  //     } catch (error) {
  //       console.error('Error processing payment:', error);
  //       applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
  //     } finally {
  //       setApplePaySessionActive(false); // Reset session state after completion
  //     }
  //   };
  
  //   try {
  //     console.log("Starting Apple Pay session...");
  //     applePaySession.begin();
  //   } catch (error) {
  //     console.error('Error starting Apple Pay session:', error);
  //     setApplePaySessionActive(false);  // Reset session state on error
  //   }
  // };

  const handleApplePay = async () => {
    if (!window.ApplePaySession) {
      console.error('Apple Pay is not available on this device/browser');
      return;
    }
  
    // Check if there's already an active Apple Pay session
    if (applePaySessionActive) {
      console.log('An Apple Pay session is already active');
      return;
    }
  
    // Mark the session as active
    setApplePaySessionActive(true);
  
    // Create ApplePay session only when necessary
    const applePaySession = new ApplePaySession(3, {
      countryCode: "US",
      currencyCode: "USD",
      supportedNetworks: ["visa", "masterCard", "amex"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: "Plenumapp",
        amount: price,
      },
    });

    // console.log("applePaySession123", applePaySession)
    
    // alert(applePaySession.onvalidatemerchant);
  
    // Set up event handlers
  
    applePaySession.onvalidatemerchant = async (event) => {
      console.log("onvalidatemerchant triggered with validationURL:", event.validationURL);
      try {
        const merchantValidationData = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/apple-pay/validate-merchant`, {
          validationUrl: event.validationURL,
        });
        console.log('Merchant validation response:', merchantValidationData);
        applePaySession.completeMerchantValidation(merchantValidationData.data);
      } catch (error) {
        console.error('Merchant validation failed:', error);
        applePaySession.completeMerchantValidation({ error: 'Merchant validation failed' });
      }
    };
  
    applePaySession.onpaymentauthorized = async (event) => {
      console.log('Apple Pay payment authorized', event);
      const payment = event.payment;
  
      // Make sure payment contains the required data
      if (!payment || !payment.token || !payment.token.paymentMethodId) {
        console.error('Payment data is missing or invalid');
        applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
        setApplePaySessionActive(false);
        return;
      }
  
      try {
        const paymentIntentRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/apple-pay/create-payment-intent`, {
          amount: price * 100, // Amount in cents
        });
  
        const { clientSecret } = paymentIntentRes.data;
  
        // Confirm payment with Stripe
        const stripePaymentResult = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/apple-pay/confirm-payment`, {
          clientSecret,
          paymentMethodId: payment.token.paymentMethodId,
        });
  
        if (stripePaymentResult.data.error) {
          console.error('Payment confirmation failed');
          applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
        } else {
          applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
      } finally {
        setApplePaySessionActive(false); // Reset session state after completion
      }
    };
  
    applePaySession.oncancel = (event) => {
      console.log('Apple Pay session cancelled', event);
      setApplePaySessionActive(false); // Reset session state when cancelled
    };
  
    // Try starting the session
    try {
      applePaySession.begin();
    } catch (error) {
      console.error('Error starting Apple Pay session:', error);
      setApplePaySessionActive(false); // Reset session state on error
    }
  };
    
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const emailToSet = decoded?.user?.email;
      setEmail(emailToSet);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const result = await getPaymentDetail();
      // console.log("qwerty      ", result.data.data.discount.percent_off);
      setPrice((result?.data?.data?.price?.unit_amount / 100).toFixed(2));
      const discountAmount =
        (price * result?.data?.data?.discount?.percent_off) / 100;
      setDiscountPercentage(result?.data?.data?.discount?.percent_off);
      const amount =
        ((result?.data?.data?.price?.unit_amount / 100) *
          result?.data?.data?.discount?.percent_off) /
        100;
      setDiscountCalculated(amount.toFixed(2));
      setTotal(
        (result?.data?.data?.price?.unit_amount / 100).toFixed(2) -
          amount.toFixed(2)
      );
    };
    getData();
  }, []);

  const handleSubscriptionButton = (value) => () => {
    setSelectedPackage(value);
  };

  const handleCard = () => {
    setShowCardOption(!showCardOption);
  };

  return (
    <>
      <section className="subscription-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "70%",
            margin: "auto",
          }}
        >
          <div
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          >
            <h3 style={{fontSize: "30px"}}>{t("payment.plenum")}</h3>
          </div>
          <div
            className="logoX"
            onClick={() => {
              navigate("/");
            }}
          >
            <h3 style={{ color: "#307ca4", cursor: "pointer" }}>X</h3>
          </div>
        </div>
        <div className="subscription-container-wrapper">
          <div className="special-offer">
            <div style={{ margin: "30px 0 20px 0" }}>
              <div
                style={{
                  color: "#6e68e4",
                  backgroundColor: "#e7e7f5",
                  width: "160px",
                  borderRadius: "13px",
                  margin: "auto",
                  padding: "8px 0",
                }}
              >
                Limited Time Offer
              </div>
            </div>
            {/* <h6>{t('payment.yourSpecialOffer')}</h6>
          <h2>{t('payment.getDiscount', { discountPercentage })}</h2> */}
            <h2>Try Plenum Premium with a 14-day free trial</h2>
            <p
              style={{
                marginTop: "26px",
                fontSize: "23px",
                fontWeight: 300,
                lineHeight: "32px",
              }}
            >
              Included in basic membership
            </p>
            <div style={{ marginTop: "30px", display: "flex" }}>
              <div
                style={{
                  marginLeft: "20px",
                  minWidth: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div className="check-icon-payment-page-text-single-line">
                Limited access to content
              </div>
            </div>

            <p
              style={{
                marginTop: "30px",
                fontSize: "23px",
                fontWeight: 300,
                lineHeight: "32px",
              }}
            >
              Your premium membership includes
            </p>

            <div style={{ marginTop: "30px", display: "flex" }}>
              <div className="check-icon-payment-page">
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div>
                <div className="check-icon-payment-page-heading">
                  50,000+ minutes of premium content
                </div>
                <div className="check-icon-payment-page-text">
                  Sleep Stories, Meditations, Nature sounds, Sleep Soundscapes,
                  Relaxing music, and more
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px", display: "flex" }}>
              <div className="check-icon-payment-page">
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div>
                <div className="check-icon-payment-page-heading">
                  New content daily
                </div>
                <div className="check-icon-payment-page-text">
                  Daily guided content to help with any of life's stressors,
                  from work, parenting, finances, and more.
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px", display: "flex" }}>
              <div className="check-icon-payment-page">
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div>
                <div className="check-icon-payment-page-heading">
                  Cancel anytime
                </div>
                {/* <div style={{ textAlign: "left", marginLeft: "15px" }}>
                  Sleep Stories, Meditations, Nature sounds, Sleep Soundscapes,
                  Relaxing music, and more
                </div> */}
              </div>
            </div>

            {/* <div
              style={{
                display: "flex",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                margin: "80px 0px",
                height: "180px",
                background: "radial-gradient(circle, #3A3E46, #1F2430)",
              }}
            >
              <button
                className={
                  selectedPackage === 0 ? "payment-Box-selected" : "payment-Box"
                }
                onClick={handleSubscriptionButton(0)}
              >
                $0
              </button>
              <button
                className={
                  selectedPackage === 0.99
                    ? "payment-Box-selected"
                    : "payment-Box"
                }
                onClick={handleSubscriptionButton(0.99)}
              >
                $0.99
              </button>
              <div>
                <button
                  className={
                    selectedPackage === 2.99
                      ? "payment-Box-selected three-dollar-box-margin"
                      : "payment-Box three-dollar-box-margin"
                  }
                  onClick={handleSubscriptionButton(2.99)}
                >
                  $2.99
                </button>
                {selectedPackage === 2.99 && (
                  <div className="three-dollar-box">
                    <p className="three-dollar-box-common-text">Most Common</p>
                  </div>
                )}
              </div>
              <button
                className={
                  selectedPackage === 4.99
                    ? "payment-Box-selected"
                    : "payment-Box"
                }
                onClick={handleSubscriptionButton(4.99)}
              >
                $4.99
              </button>
            </div> */}

            <div
              className="check-icon-payment-page-box-1"
              style={{ marginTop: "40px" }}
            >
              <div
                style={{
                  textAlign: "left",
                  fontSize: "23px",
                  fontWeight: 500,
                }}
              >
                Here's Your Plan.
              </div>
              <div
                style={{
                  marginTop: "25px",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Don't worry, you can cancel at any time.
              </div>

              <div
                className="summary-item summary-item-content"
                style={{ marginTop: "20px" }}
              >
                <p style={{ fontSize: "18px", fontWeight: 400 }}>Yearly Plan</p>
                <p style={{ fontSize: "18px", fontWeight: 400 }}>
                  US${price}/yr.
                  {/* US$39.99/yr. */}
                </p>
              </div>
              <div className="summary-item summary-item-content-below border_bottom-checkout-page">
                {!is_trial_taken && (
                  <>
                    {/* <p >{t("payment.oneMonthPremium")}</p> */}
                    <p
                      style={{
                        color: "#1295D2",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      14-day Trial
                    </p>
                    {/* <p>${selectedPackage}</p> */}
                    <p
                      style={{
                        color: "#1295D2",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      FREE
                    </p>
                  </>
                )}
              </div>
              {!is_trial_taken && (
                <div className="subscription-total-borderless">
                  <p style={{ fontSize: "18px", font: 700 }}>
                    Total due today*
                  </p>
                  <p style={{ fontSize: "18px", font: 700 }}>US$0.00</p>
                </div>
              )}

              <div className="subscription-total">
                {/* <p>{t("payment.total")}</p> */}
                <span style={{ fontSize: "18px", font: 400 }}>
                  {is_trial_taken
                    ? "Total due today*"
                    : "Total due after trial"}
                </span>
                <span style={{fontSize: "18px", font:400}}>US${price}</span>
                {/* <span style={{ fontSize: "18px", font: 400 }}>US$39.99</span> */}
              </div>
              {!is_trial_taken && (
                <div
                  style={{
                    marginTop: "10px",
                    color: "#5c7a9c",
                    textAlign: "left",
                    fontSize: "12px",
                  }}
                >
                  {/* After your offer ends, you will be automatically billed for
                  Plenum Premium at US$39.99. */}
                  After your offer ends, you will be automatically billed for
                  Plenum Premium at US${price}.
                </div>
              )}
            </div>
          </div>

          {/* <div className="offer-details-wrapper"> */}
          {/* <p className="offer-content">{t("payment.offerAppliedTo")}</p>
            <p className="email">{email}</p> */}
          {/* <div className="order-summary">
              <h5>{t("payment.orderSummary")}</h5>
              <div className="summary-item summary-item-content">
                <p>{t("payment.oneYearPremium")}</p>
                <p>${price}</p>
              </div>
              <div className="summary-item">
                <p>{t("payment.discount", { discountPercentage })}</p>
                <p>-${discountCalculated}</p>
              </div>
            </div> */}
          {/* </div> */}
          {/* <div className="subscription-total">
            <p>{t("payment.totalAfterDiscount")}</p>
            <p>${total}</p>
          </div> */}
          {/* <div className="payment-details">{t("payment.paymentDetails")}</div> */}
          <div className="check-icon-payment-page-box-1">
            <div style={{ fontSize: "23px", fontWeight: 500 }}>
              2. Choose your payment method
            </div>
            {/* <div
              style={{ 
                marginTop: "20px",
                borderRadius: "50px",
                backgroundColor: "#ffc439",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
              }}
            >
              <span
                style={{
                  color: "#013087",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                Pay
              </span>
              <span
                style={{
                  color: "#009cde",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                Pal
              </span>
            </div> */}
            {/* <div style={{ margin: "20px 0", textAlign: "center" }}>Or</div> */}
            {showApplePay && (
              <>
                <div
                  onClick={handleApplePay}
                  style={{
                    marginTop: "20px",
                    borderRadius: "50px",
                    backgroundColor: "#000",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                    color: "white",
                  }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Apple Pay
                  </span>
                </div>

                <div style={{ margin: "20px 0", textAlign: "center" }}>Or</div>
              </>
            )}
            <div
              onClick={handleCard}
              style={{
                borderRadius: "50px",
                border: "1px solid black",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
                marginTop: "10px",
              }}
            >
              <MdCreditCard />
              <span style={{ marginLeft: "10px" }}>Credit or Debit</span>
            </div>
          </div>
          {/* ================================== payment Form ============================== */}
          {showCardOption && (
            <div className="payment-form-wrapper bar-content-below border_bottom-checkout-page">
              {/* <p className="payment-option">Or</p> */}
              {/* <PaymentForm /> */}
              <CreatePayment amount={totalAmount} />
            </div>
          )}
          {!showCardOption && (
            <div style={{borderBottom: "1px solid #d3d3d3", paddingBottom: !showCardOption ? "30px" : "0px"}}>
              {!is_trial_taken && (
                <p style={{ margin: "40px 0 30px 0", color: "#bababa" }}>
                  By clicking "Start Free Trial", you agree to be automatically
                  billed for Plenum Premium at US${price} after your trial ends
                  every year until cancelled. <span style={{textDecoration: "underline"}}>Cancel anytime.</span>
                </p>
              )}
              <button
                disabled={true}
                // disabled={!stripe || loading || !isPaymentElementComplete}
                // className="payment-submit-btn text-white w-full p-5 bg-black mt-2 rounded-md font-bold disabled:opacity-50 disabled:animate-pulse"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#cccccc",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                  border: "none",
                  margin: "auto",
                  width: "100%",
                  color: "white",
                  marginTop: is_trial_taken ? "50px" : "20px",
                }}
              >
                {/* {!loading ? `Get Plenum Premium` : "Processing..."} */}
                {is_trial_taken ? `Pay Now` : `Start Free Trial`}
              </button>
              <p style={{ color: "#bababa", marginTop: "30px" }}>
                <CiLock style={{ position: "relative", top: 5, left: -5 }} />{" "}
                All Transactions are secure and encrypted.
              </p>
            </div>
          )}

          <div>
            <div style={{ marginTop: "30px", display: "flex" }}>
              <div className="check-icon-payment-page">
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div>
                <div className="check-icon-payment-page-heading">
                  Get in-the-moment relief from stress and anxiety
                </div>
                <div className="check-icon-payment-page-text">
                  Expert-led sessions and grounding exercises to quickly relax
                  your mind
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px", display: "flex" }}>
              <div className="check-icon-payment-page">
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div>
                <div className="check-icon-payment-page-heading">
                  Fall (and stay) asleep naturally
                </div>
                <div className="check-icon-payment-page-text">
                  Sleep Stories and meditations designed to keep you relaxed
                  through the night
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px", display: "flex" }}>
              <div className="check-icon-payment-page">
                <IoCheckmark
                  style={{ color: "white", width: "15px", height: "15px" }}
                />
              </div>
              <div>
                <div className="check-icon-payment-page-heading">
                  Learn with guidance from the best
                </div>
                <div className="check-icon-payment-page-text">
                  Our instructors make mindfulness easy and welcoming, even for
                  beginners
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payment;
