import React, { useState, useEffect } from "react";
import { MdOutlineCreditCard } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import "../style/profile.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import ProfileTabs from "./ProfileTabs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAudio } from "../AudioContext/AudioContext";

const ProfileList = ({ handleProfileTitle, setShowLogout }) => {
  const { t, i18n } = useTranslation();
  const [profileModal, setProfileModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [email, setEmail] = useState("");
  const [activeTab, setActiveTab] = useState(null);
  const navigate = useNavigate();
  const { setHandleStopButton, setUser_id, setFavouriteTrackIds } = useAudio();
  const handleModal = () => {
    setProfileModal(!profileModal);
  };
  const profileData = [
    {
      icon: <MdOutlineCreditCard />,
      title: t("profile.profileList.0"),
      name: "manage_subscription",
    },
    {
      icon: <FaRegUserCircle />,
      title: t("profile.profileList.1"),
      name: "account_details",
    },
    {
      icon: <CiLock />,
      title: t("profile.profileList.2"),
      name: "change_password",
    },
    {
      icon: <RiLogoutBoxRLine />,
      title: t("profile.profileList.3"),
      name: "log_out",
    },
  ];
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleInput = (e) => {
    const componentName = e.currentTarget.getAttribute("name");
    // <ProfileTabs activeTabPassed={componentName}/>
    if (componentName == "log_out") {
      setUser_id(null);
      setHandleStopButton(true);
      setFavouriteTrackIds([]);
      localStorage.removeItem("token");
      localStorage.removeItem("social");
      toast.success("Logged out!");
      navigate("/Home/1");
    } else {
      setActiveTab(componentName);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const emailToSet = decoded?.user?.email;
      setEmail(emailToSet);
    }
  }, []);
  return (
    <>
      {activeTab ? (
        <ProfileTabs
          activeTabPassed={activeTab}
          handleProfileTitle={handleProfileTitle}
          setShowLogout={setShowLogout}
        />
      ) : (
        <section className="user-login-profile section-spacing">
          <div className="user-login-profile-wrapper">
            <div className="user-login-profile-content">
              {profileData?.map((data, index) => {
                return (
                  <>
                    <button
                      style={{ border: "none" }}
                      className="user-profile-inner-content"
                      key={index}
                      name={data?.name === "log_out" ? data?.name : data?.title}
                      onClick={handleInput}
                    >
                      <div className="user-profile-inner-content-left">
                        {data?.icon}
                        <p>{data?.title}</p>
                      </div>
                      <div className="user-profile-inner-content-right">
                        <IoIosArrowForward />
                      </div>
                    </button>
                  </>
                );
              })}
              {/* <div className="user-profile-inner-content">
                <button onClick={() => changeLanguage("en")}>English</button>
                <button onClick={() => changeLanguage("fr")}>Français</button>
                <button onClick={() => changeLanguage("ur")}>اردو</button>
              </div> */}
            </div>
          </div>
          <div className="user-email-details">
            {/* <div style={{ paddingRight: "3rem" }}> */}
              <p style={{textAlign: "center"}}>
                {t("profile.Logged in as")}: {email}
              </p>
            {/* </div> */}
            {/* <div style={{ paddingRight: "3rem" }}> */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ul>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/contact");
                    }}
                  >
                    {t("profile.Support")}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/privacy-policy");
                    }}
                    to="#"
                  >
                    {t("profile.Privacy")}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/terms");
                    }}
                  >
                    {t("profile.Terms")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProfileList;
